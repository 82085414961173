import React from 'react';
import PropTypes from 'prop-types';

const CallToActionServicesHero = ({ primary = `gray-500` }) => {
  return (
    <div className="sm:flex -mb-12 relative up-50">
      <p
        className={`cta-left bg-${primary} p-8 font-bold font-display leading-tight text-white text-xl md:text-2xl lg:text-3xl uppercase`}
      >
        <span className="inline-block mr-8 sm:mr-24 my-8 whitespace-no-wrap">
          What we do
        </span>
      </p>
    </div>
  );
};

CallToActionServicesHero.propTypes = {
  primary: PropTypes.string,
};

export default CallToActionServicesHero;
