import React from 'react';
import PropTypes from 'prop-types';

const Service = ({ children, Icon, id, primary, title }) => {
  return (
    <div id={id} className="flex mx-auto">
      <div className="flex flex-wrap sm:flex-no-wrap mb-4 mt-40 sm:ml-32 sm:mt-8 mx-auto relative">
        <div
          className={`absolute bg-${primary} flex inset-x-0 items-center justify-center m-auto p-8 rounded-full top-0 up-50 h-48 sm:h-56 w-48 sm:w-56 sm:inset-y-0 sm:left-0 sm:left-50 sm:right-auto sm:y-0`}
        >
          <Icon className="fill-current text-white h-full w-full" />
        </div>
        <div className="bg-gray-200 max-w-3xl mx-auto p-8 pt-32 sm:pl-40 sm:pt-8 sm:pr-20">
          <h3
            className={`font-bold font-display leading-none uppercase text-${primary} text-3xl text-center w-full`}
          >
            {title}
          </h3>
          <div className="mt-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

Service.propTypes = {
  children: PropTypes.node.isRequired,
  Icon: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  primary: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Service;
