import React from 'react';
import PropTypes from 'prop-types';

import Service from './Service';
import NewRoofsIcon from '../icons/NewRoofsIcon';
import WallCladdingIcon from '../icons/WallCladdingIcon';
import GuttersIcon from '../icons/GuttersIcon';
import GutterGuardIcon from '../icons/GutterGuardIcon';
import FasciaIcon from '../icons/FasciaIcon';
import DownpipesIcon from '../icons/DownpipesIcon';
import SkylightsIcon from '../icons/SkylightsIcon';

const OurServices = ({ primary = `gray-500` }) => {
  return (
    <div className="max-w-6xl mx-auto px-8 py-12 w-full">
      <div className="max-w-xl mx-auto">
        <h2
          className={`font-bold font-display leading-none mb-4 text-4xl text-${primary} uppercase`}
        >
          Our Services
        </h2>
        <p>
          Mark is a fully qualified Roof Plumber with{' '}
          {new Date().getFullYear() - 1999} years experience. <br />
          Below is a list of some of the services we offer:
        </p>
      </div>
      <div className="flex flex-wrap">
        <Service
          title="New Roofs"
          Icon={NewRoofsIcon}
          id="new-roofs"
          primary={primary}
        >
          <p>
            It is important to us that your new roof is not only visually
            attractive but also has optimal functionality. The roof of your home
            is the most noticeable and impression-making feature of your
            property. Additionally, it is the predominant feature protecting you
            and your family from harsh weather and varying outdoor elements.
            Balancing elegance with purpose is key.
          </p>
          <p className="mt-4">
            When working through the initial design phase of your roof, we will
            consider all other stylistic features of your home to help you
            choose colours and materials that will complement the rest of your
            building, rather than contrast against them. As a roof plumber, Mark
            will ensure that your roof adheres to all safety standards.
          </p>
        </Service>
        <Service
          title="Wall Cladding"
          Icon={WallCladdingIcon}
          id="wall-cladding"
          primary={primary}
        >
          <p>
            Effective cladding should insulate and protect your property without
            detracting from the visual appeal of the exterior. Our cladding
            materials and techniques are proven to provide the ultimate defence
            for your home. They are highly functional, enabling a layer of
            protection as well as being a solution to temperature control.
          </p>
          <p className="mt-4">
            Our team will ensure that your building cladding is entirely safe
            for residents and meets all national standards. Keeping your family
            and residents comfortable and protected is our main focus when
            providing cladding options.
          </p>
        </Service>
        <Service
          title="Gutters"
          Icon={GuttersIcon}
          id="gutters"
          primary={primary}
        >
          <p>
            Every home needs a functioning gutter system that will protect the
            roof from erosion and provide an answer to heavy weather systems.
            However, safeguarding your home from rainwater damage should not be
            an expensive or complex task. With much experience in installing
            effective gutters, Mark understands how to install them in a timely
            and cost-effective manner.
          </p>
          <p className="mt-4">
            When sorting your gutter needs, Mark will take the time to offer
            solutions that match your home’s visual appeal. Instead of rushing
            the process, we consult with you as the client to ensure you are
            happy. You will receive the opportunity to have input on colours and
            varieties so that you can be entirely satisfied with the project.
            Every gutter that Mark has mounted has been 100% successful for
            protecting properties across New South Wales.
          </p>
        </Service>
        <Service
          title="Gutter Guard"
          Icon={GutterGuardIcon}
          id="gutter-guard"
          primary={primary}
        >
          <p>
            Although gutters are effective in draining rainwater, they can
            present issues relating to debris collection and clogging.
            Properties that do not have gutter guards connected to their gutter
            systems will rapidly realise that their gutters are collecting
            leaves, branches, rubbish and other debris. When blocked with these
            items, a gutter will essentially become useless. It could also
            become dangerous when the crusty plant material is left to start or
            spread fires.
          </p>
          <p className="mt-4">
            There is a smart solution for stopping gutter blockages — quality
            gutter guards will certainly reduce the likelihood of debris
            collecting. Gutter guards are an investment for the future, as they
            result in a reduced frequency of gutter cleanings.
          </p>
        </Service>
        <Service title="Fascia" Icon={FasciaIcon} id="fascia" primary={primary}>
          <p>
            In addition to new gutters, most homeowners will consider a fascia
            or roofline. This band, or ribbon, is generally mounted at the point
            where the roof meets the outer walls of the house. Not only does it
            carry the roof guttering, but it also supports the bottom row of
            tiling on the home. Furthermore, many homeowners believe it
            simplifies and enhances the appearance of the building.
          </p>
          <p className="mt-4">
            During intense weather systems or torrential downpours, a fascia
            board may be the crucial element that stops your gutters from
            becoming damaged. As an experienced tradesman, Mark has learnt that
            the most effective and enduring gutter systems have a fascia
            underneath them for optimal support.
          </p>
        </Service>
        <Service
          title="Downpipes"
          Icon={DownpipesIcon}
          id="downpipes"
          primary={primary}
        >
          <p>
            Downpipes are an essential addition to the exterior of your home.
            Typically, downpipes are installed at the same time as new roofs and
            gutters. However, Mark can install downpipes to already guttered
            properties if they were excluded during your original build.
          </p>
          <p className="mt-4">
            As a homeowner, it is vital that you source your downpipes from a
            reputable roofer or builder. If your downpipes are not installed
            incorrectly, they may collect unwanted debris and block water from
            exiting your gutters.
          </p>
        </Service>
        <Service
          title="Skylights"
          Icon={SkylightsIcon}
          id="skylights"
          primary={primary}
        >
          <p>
            Natural sunlight is a highly sought-after feature in all homes.
            Skylights allow for outdoor light to ooze into the living spaces of
            your home and amplify the mood in any room. As well as reducing
            electrical lighting costs, skylights provide ventilation to your
            property.
          </p>
          <p className="mt-4">
            Having incorporated skylight features into the roofs of many homes,
            Mark is an expert when it comes to knowing the best and most
            practical places for skylights. Mark’s expertise in installing
            skylights means that you will never suffer from leaks or damage. We
            only use the highest quality skylights from world leaders VELUX.
            Their range of expertly designed skylights are guaranteed to
            withstand the harsh Australian climate for years to come.
          </p>
        </Service>
      </div>
    </div>
  );
};

OurServices.propTypes = {
  primary: PropTypes.string,
};

export default OurServices;
