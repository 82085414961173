import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ServicesHero from '../components/ServicesHero';
import CallToActionServicesHero from '../components/CallToAction/ServicesHero';
import OurServices from '../components/Services/OurServices';
// import SeeOurProjects from '../components/SeeOurProjects';
import CallToActionContact from '../components/CallToAction/Contact';
import Contact from '../components/Contact';

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" />
    <ServicesHero />
    <CallToActionServicesHero primary="pink-600" />
    <OurServices primary="pink-600" />
    {/* <SeeOurProjects /> */}
    <CallToActionContact primary="gray-500" />
    <Contact primary="gray-500" secondary="gray-400" />
  </Layout>
);

export default ServicesPage;
